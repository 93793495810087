import CharlesMeta from "components/common/meta/CharlesMeta";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FAQTextSectionList from "../common/FAQTextSectionList/FAQTextSectionList";
import { Layout } from "components/common";
import React from "react";
import Row from "react-bootstrap/Row";
import Section from "components/common/Section";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import categories from "./data";
import cx from "classnames";
import './styles.sass';

const title = "Foire Aux Questions"
const description = "Comment télé-consulter un médecin spécialiste sur Charles.co ? Comment commander en ligne des médicaments dans une pharmacie ? Retrouvez les questions/réponses sur notre FAQ."

const IndexPage = () => (
  <Layout showLinksInFooter={false}>
    <CharlesMeta
      data={{
        title: "FAQ / Foire Aux Questions | Toutes vos réponses sur Charles.co",
        description: description
      }}
    />
    <StructuredWebPage
      title={title}
      description={description}
    />
    <div className="text-left">
      <Section className="mt-4 mt-lg-0 pb-lg-5 p-5 heading-section main-section faq-landing-section">
        <Container>
          <h1 className="h1 text-center main-title-landing">
            Foire Aux Questions
          </h1>
        </Container>
      </Section>
      <Section className="pb-0 pb-md-1 mt-0 mb-5">
        <Container>
          <Row className="faq-map-row">
            <Col xs={12} lg={5} className="faq-about-charles-col">
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">
                  À propos de Charles
                </h2>
                <p className="faq-accordion-sections text-left">
                  Charles est une Clinique Digitale qui aide les patients
                  adultes à prendre en main leurs problèmes de santé du
                  quotidien à distance, que ce soit les troubles du sommeil, les
                  problèmes intimes, les troubles digestifs ou encore la chute
                  de cheveux.</p>
                <p className="faq-accordion-sections text-left"> Charles est une solution d’accompagnement qui met
                  le patient en relation avec des médecins experts et permet
                  ensuite une prise en charge personnalisée selon les
                  recommandations du médecin : livraison à domicile de
                  traitements (ordonnance à la discrétion du médecin), thérapie
                  digitale, dispositif médicaux, suivi psychologique etc. le
                  tout immédiatement et sans vous déplacer.
                </p>
              </div>
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">
                  {categories[1].categoryTitle}
                </h2>
                <FAQTextSectionList
                  sections={categories[1].sections}
                  openFirst={false}
                  id="categories_1"
                  className="d-block faq-accordion-sections"
                  />
              </div>
            </Col>
            <Col xs={12} lg={{ offset: 2, span: 5 }}>
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">
                  {categories[0].categoryTitle}
                </h2>
                <FAQTextSectionList
                  sections={categories[0].sections}
                  openFirst={false}
                  id="categories_0"
                  className="d-block faq-accordion-sections"
                  />
              </div>
              <div className="faq-accordion-div">
                <h2 className="section-title faq-category-title">
                  {categories[2].categoryTitle}
                </h2>
                <FAQTextSectionList
                  sections={categories[2].sections}
                  openFirst={false}
                  id="categories_2"
                  className="d-block faq-accordion-sections"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  </Layout>
);

export default IndexPage;
